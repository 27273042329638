import React, { useState } from "react";
import styled from "styled-components";

import { Container, FlexGrid, H1, Button } from "@util/standard";
import { pageWidthMargin } from "@util/helper";
import Hero from "@shared/hero";
import CollectionAccordian from "@shared/collectionAccordian";
import CategoryPreview from "@shared/categoryPreview";
import { useStore } from "@state/store";
import {
  Maybe,
  SanityBundles,
  SanityCategory,
  SanityCollection,
  SanityHero,
  SanityHomePage,
  SanityProduct,
  SanityProductAu,
  SanitySeo,
} from "@graphql-types";
import RecipePreviewCarousel from "@components/recipes/recipePreviewCarousel";
import ProductPreview from "@shared/productPreview";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import AllergensFilter from "@shared/allergensFilter";
import { HeroCarousel, Breadcrumb } from "@global";
import SEO from "./seo";
import Layout from "./layout";
import {
  BreadcrumbPageContext,
  isSanityBundles,
  isSanityProduct,
} from "@util/types";
import { useCheckScreenWidth } from "@util/hooks";
import BundlesMenu from "./sub/bundlesMenu";
import BundlePreview from "./bundlePreview";

const StyledContainer = styled(Container)`
  div[class^="collectionAccordian__AccordionsContainer"] {
    width: 100%;
  }
`;
interface Props {
  categoryList?: Maybe<Maybe<SanityCategory>[]>;
  collectionList?: Maybe<Maybe<SanityCollection>[]>;
  productList?: Maybe<Maybe<SanityProduct>[]>;
  bundleList?: Maybe<Maybe<SanityBundles>[]>;
  hero?: Maybe<SanityHero>;
  additionalHeros?: Maybe<SanityHero>[];
  selectedCollection?: string;
  selectedCategory?: string;
  selectedCategoryName?: string;
  isShopAll?: boolean;
  title?: string | null;
  seo: Maybe<SanitySeo> | undefined;
  slug?: string;
  pageContext: BreadcrumbPageContext;
  homepageData?: SanityHomePage;
  iban?: string;
  isBundle?: boolean;
  bundleId?: string;
  bundleProduct?: SanityProduct | SanityProductAu | undefined;
  categoryColour?: string;
}

interface ProductOrBundleGridProps {
  data: Maybe<SanityBundles | SanityProduct>[];
  selectedCollection?: string;
  selectedCategory?: string;
  selectedCategoryName?: string;
  isBundle?: boolean;
  bundleId?: string;
  categoryColour?: string;
}

const CollectionTemplate = ({
  categoryList,
  productList,
  hero,
  additionalHeros,
  selectedCollection,
  selectedCategory,
  selectedCategoryName,
  isShopAll,
  title,
  seo,
  slug,
  pageContext,
  homepageData,
  collectionList,
  iban,
  isBundle,
  bundleId,
  bundleProduct,
  bundleList,
  categoryColour,
}: Props) => {
  const { allergenFilter } = useStore();
  const { isMobileWidth } = useCheckScreenWidth();
  const selectedAllergen = allergenFilter?.name;
  const margin = pageWidthMargin();

  //This applies only on category page to filter through allergens
  let productData: Maybe<SanityProduct>[] = [];
  if (
    selectedAllergen &&
    selectedAllergen !== "" &&
    selectedAllergen !== "None"
  ) {
    productData = productList
      ? productList?.filter(product => {
          let hasProd = false;
          product?.content?.info?.allergens?.forEach(allergen => {
            if (allergen?.title === selectedAllergen) hasProd = true;
          });
          return hasProd;
        })
      : [];
  } else {
    productData = productList ?? [];
  }

  return (
    <>
      <SEO seoData={seo} slug={slug} />
      <Layout>
        <Container backgroundColor="white" flexDirection="column">
          {hero ? (
            <>
              {hero && additionalHeros && additionalHeros.length > 0 ? (
                <HeroCarousel hero={hero} additionalHeros={additionalHeros} />
              ) : (
                <Hero sanityHero={hero} />
              )}
            </>
          ) : (
            title && (
              <Container
                height="320px"
                padding={`55px ${margin} 20px`}
                backgroundColor="merlin"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <H1 color="white">{title}</H1>
              </Container>
            )
          )}
          <Breadcrumb
            pageContext={pageContext}
            crumbLabel={title ?? undefined}
          />
          <StyledContainer
            height="auto"
            width="100%"
            padding={`55px ${margin} 0`}
            mobilePadding={margin}
            display="flex"
            flexDirection="row"
          >
            <FlexGrid
              gridMargin="120px"
              laptopGridMargin="75px"
              tabletGridMargin="55px"
            >
              <Container
                width="calc(230px + 120px)"
                laptopWidth="300px"
                tabletWidth="250px"
                margin="0 0 55px"
                flexDirection="column"
                mobileWidth="100%"
              >
                {isMobileWidth !== true && !isBundle && (
                  <>
                    <AllergensFilter />
                    <CollectionAccordian
                      isShopAll={isShopAll}
                      selectedCollection={selectedCollection}
                      selectedCategory={selectedCategory}
                      regionalisedCategoryList={categoryList}
                      regionalisedCollectionList={collectionList}
                    />
                  </>
                )}
                {isBundle && (
                  <BundlesMenu
                    bundleId={bundleId}
                    bundleProduct={bundleProduct}
                  />
                )}
              </Container>

              <Container
                width="calc(100% - 230px - 120px)"
                laptopWidth="calc(100% - 300px)"
                tabletWidth="calc(100% - 250px)"
                mobileWidth="100%"
                flexDirection="column"
              >
                {/* Shows message when no product/category found */}
                {productList &&
                  !Boolean(productList.length) &&
                  bundleList &&
                  !Boolean(bundleList.length) &&
                  categoryList &&
                  !categoryList.length && <p>Ops, there is nothing in here!</p>}

                {productList && Boolean(productList.length) ? (
                  <ProductOrBundleGrid
                    data={productList}
                    selectedCategoryName={selectedCategoryName}
                    selectedCategory={selectedCategory}
                    isBundle={isBundle}
                    bundleId={bundleId}
                    categoryColour={categoryColour}
                  />
                ) : bundleList && Boolean(bundleList?.length) ? (
                  <ProductOrBundleGrid
                    data={bundleList}
                    selectedCategoryName={selectedCategoryName}
                    selectedCategory={selectedCategory}
                    isBundle={isBundle}
                    bundleId={bundleId}
                    categoryColour={categoryColour}
                  />
                ) : null}
                {
                  // Load categories if on collection page or shop all page
                    isShopAll && categoryList?.map(category => {
                      if (!category) return;

                      return (
                        <Container
                          key={category?.main?.slug?.current}
                          position="relative"
                        >
                          <CategoryPreview
                            slug={isShopAll ? `shop-all` : selectedCollection}
                            width={`calc(100% + ${margin})`}
                            margin={`0 -${margin} 100px 0`}
                            tabletMargin={`0 -${margin} 60px 0`}
                            sanityCategory={category}
                            showTabletNav={true}
                            filter
                            iban={iban}
                          />
                        </Container>
                      );
                    })
                }
              </Container>
            </FlexGrid>
          </StyledContainer>

          {/* Same component as the one on the homepage */}
          {homepageData?.gifting?.giftingBlock && (
            <ImageTextBlockWithCarousel
              singleBlockData={homepageData?.gifting?.giftingBlock}
              customHeight="650px"
            />
          )}

          {/* Same component as the one on the homepage */}
          {homepageData && homepageData.featuredRecipes && (
            <RecipePreviewCarousel recipes={homepageData.featuredRecipes} />
          )}
        </Container>
      </Layout>
    </>
  );
};

export default CollectionTemplate;

const ProductOrBundleGrid = ({
  data,
  selectedCategoryName,
  selectedCategory,
  isBundle,
  bundleId,
  categoryColour,
}: ProductOrBundleGridProps) => {
  const [shownNumber, setShownNumber] = useState(15);

  return (
    <FlexGrid gridMargin="45px" laptopGridMargin="35px" tabletGridMargin="20px">
      {data.slice(0, shownNumber).map(product => {
        if (product == null) return;

        return (
          <Container
            key={product.id}
            margin="0 0 100px"
            tabletMargin="0 0 55px"
          >
            {isSanityBundles(product) && <BundlePreview data={product} />}
            {isSanityProduct(product) && (
              <ProductPreview
                isBundle={isBundle}
                bundleId={bundleId}
                category={selectedCategory}
                categoryName={selectedCategoryName}
                noMargin={true}
                product={product}
                categoryColour={categoryColour}
              />
            )}
          </Container>
        );
      })}
      {shownNumber < data.length && (
        <Container width="100%" margin="0 0 100px" tabletMargin="0 0 55px">
          <Button
            onClick={() => {
              setShownNumber(pre => pre + 15);
            }}
          >
            Load More
          </Button>
        </Container>
      )}
    </FlexGrid>
  );
};
