import * as React from "react";

import styled from "styled-components";
import Accordion from "react-collapsy";
import { Link } from "@global";
import {
  assets,
  colors,
  colorsRGB,
  MOBILE_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  DEFAULT_FONT,
} from "@util/constants";
import { Container, P } from "@util/standard";

interface Props {
  selectedCategory?: string | null;
  selectedCollection?: string | null;
  isShopAll?: boolean;
  regionalisedCategoryList?: any;
  regionalisedCollectionList?: any;
}

const AccordionsContainer = styled.div`
  width: 230px;
  .Accordion {
    margin: 0px;
    border: none;
    border-bottom: 1px solid ${colorsRGB.accordianBorder()};
  }

  .Accordion__body {
    padding: 0px 15px 0;
  }

  .Accordion__header {
    height: auto;
    cursor: pointer;
    background: transparent;
    padding: 0;
    font-family: ${DEFAULT_FONT};

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      padding: 80px 20px;
    }
  }

  .Accordion__header:after {
    width: 10px;
    height: 5px;
    right: 18px;
    background: url(${assets.arrowDownDark});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
      right: 5px;
    }
  }

  h4,
  h3,
  p,
  li {
    color: ${colors.green};
  }
`;

const CollectionAccordian = (props: Props) => {
  const {
    selectedCategory,
    selectedCollection,
    isShopAll,
    regionalisedCategoryList,
    regionalisedCollectionList,
  } = props;
  const shopAllId = "shop-all";

  return (
    <AccordionsContainer>
      {regionalisedCollectionList?.map((accordion: any) => {
        if (!accordion) return;
        const accordionMain = accordion?.main;
        if (!accordionMain) return;

        const currentCollection = accordionMain.slug?.current;
        const isSelectedCollection = selectedCollection === currentCollection;

        return (
          <Accordion
            title={
              <Container width="100%" tabletWidth="92.5%" margin="0px auto">
                <Container justifyContent="flex-start" width="100%">
                  <Link
                    internallink
                    url={`${currentCollection}`}
                    linktext={accordionMain.title}
                    fontSize={15}
                    fontWeight={isSelectedCollection ? "600" : "400"}
                    padding="1em 0"
                  />
                </Container>
              </Container>
            }
            key={accordionMain.title}
            isOpen={isSelectedCollection}
          >
            <Container
              height="auto"
              justifyContent="flex-start"
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              margin="0 0 15px"
            >
              {accordionMain.categories &&
                accordionMain.categories.map((category, i) => {
                  if (!category) return;
                  const categoryMain = category.main;
                  if (!categoryMain) return;

                  const currentCategory = categoryMain.slug?.current;
                  if (
                    selectedCategory &&
                    selectedCategory === currentCategory
                  ) {
                    return (
                      <P
                        key={`${i}-${categoryMain.slug?.current}`}
                        fontSize={15}
                        margin="6px 0px"
                        fontWeight="600"
                      >
                        {categoryMain.title}
                      </P>
                    );
                  }
                  return (
                    <Link
                      internallink
                      alternateFontSize
                      linktext={categoryMain.title}
                      url={`${currentCollection}/${currentCategory}`}
                      padding="6px 0px"
                      key={`${i}-${categoryMain.slug?.current}`}
                      whitespace="normal"
                    />
                  );
                })}
            </Container>
          </Accordion>
        );
      })}

      {/* Shop All */}
      <Accordion
        isOpen={isShopAll || selectedCollection === shopAllId}
        title={
          <Container width="100%" tabletWidth="92.5%" margin="0px auto">
            <Container justifyContent="flex-start" width="100%">
              <Link
                internallink
                url={`${shopAllId}`}
                linktext="Shop All"
                fontSize={15}
                fontWeight={
                  isShopAll || selectedCollection === shopAllId ? "600" : "400"
                }
                padding="1em 0"
              />
            </Container>
          </Container>
        }
        key="Shop_All"
      >
        <Container
          height="auto"
          justifyContent="flex-start"
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          margin="0 0 15px"
        >
          {regionalisedCategoryList?.map((category, i) => {
              const categoryMain = category?.main;
              if (!categoryMain) return;

              const currentCategory = categoryMain.slug?.current;
              if (selectedCategory && selectedCategory === currentCategory) {
                return (
                  <P
                    key={`${i}-${categoryMain.slug?.current}`}
                    margin="6px 0px"
                    fontSize={15}
                    fontWeight="600"
                  >
                    {categoryMain.title}
                  </P>
                );
              }
              return (
                <Link
                  internallink
                  alternateFontSize
                  linktext={categoryMain.title}
                  url={`${shopAllId}/${currentCategory}`}
                  padding="6px 0px"
                  key={`${i}-${categoryMain.slug?.current}`}
                  whitespace="normal"
                />
              );
            })}
        </Container>
      </Accordion>
    </AccordionsContainer>
  );
};

export default CollectionAccordian;
