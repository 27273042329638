import { Button, IconButton } from "@global";
import { BundleProduct } from "@state/types";
import { colorsRGB } from "@util/constants";
import { getBundle } from "@util/helper";
import {
  useAddToCart,
  useCheckScreenWidth,
  useUpdateProductToBundle,
} from "@util/hooks";
import { A, Container, P } from "@util/standard";
import { navigate } from "gatsby";
import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { SanityProduct, SanityProductAu } from "@graphql-types";
import { useStore } from "@state/store";

interface Props {
  bundleId: string | undefined;
  bundleProduct: SanityProduct | SanityProductAu | undefined;
}

const StyledContainer = styled(Container)<{ padding?: number }>`
  border: none;
  border-bottom: 1px solid ${colorsRGB.accordianBorder()};
  ${({ padding }) =>
    padding &&
    `padding-top: ${padding}px;
  padding-bottom: ${padding}px;`}
`;

const BundlesMenu = (props: Props) => {
  const bundle = getBundle(props.bundleId);
  if (bundle == null) return null;
  const { products, totalProductsAllowed = 0 } = bundle;

  const [emptyProductsCount, setEmptyProductsCount] = useState(
    products ? totalProductsAllowed - products.length : totalProductsAllowed
  );

  const { openCartDrawer, shopify, setBundles } = useStore();

  useEffect(() => {
    setEmptyProductsCount(
      products ? totalProductsAllowed - products.length : totalProductsAllowed
    );
  }, [bundle]);

  const [productToRemove, setProductToRemove] = useState<BundleProduct>();
  const { isMobileWidth } = useCheckScreenWidth();

  const handleRemoveProductClick = useUpdateProductToBundle(
    props.bundleId,
    productToRemove,
    productToRemove?.lineId
  );

  useEffect(() => {
    if (productToRemove) {
      handleRemoveProductClick();
    }
  }, [productToRemove]);

  //Modal
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 1000 },
  };

  const handleSubmit = () => {
    const defaultVariant =
      props.bundleProduct?.content?.shopify?.defaultVariant?.variantId;
    const attributes = products?.map((product, index) => {
      return {
        key: `Product ${index + 1}`,
        value: product.title,
      };
    });
    const attrImgs = products?.map((product, index) => {
      return {
        key: `_Product img ${index + 1}`,
        value: product.prodImage?.asset?.url,
      };
    });
    const customAttributes = attributes?.concat(attrImgs);

    const lineItems = [
      {
        merchandiseId: "gid://shopify/ProductVariant/" + defaultVariant,
        quantity: 1,
        attributes: customAttributes,
      },
    ];

    useAddToCart(lineItems, shopify, openCartDrawer, closeModal);
  };

  return (
    <Container flexDirection="column" justifyContent="center">
      <p>
        {products ? products.length : 0}/{totalProductsAllowed} Products
      </p>
      {isMobileWidth !== true && (
        <Container height="52px">
          {products &&
            products.length > 0 &&
            products.map((product: BundleProduct) => {
              return (
                <IconButton
                  icon="bundleFull"
                  height="52px"
                  onClick={() => {
                    navigate(
                      `/shop/${product.slug}?bundleId=${props.bundleId}`
                    );
                  }}
                />
              );
            })}

          {emptyProductsCount > 0 &&
            Array.from(Array(emptyProductsCount).keys()).map(() => {
              return <IconButton icon="bundleEmpty" height="52px" />;
            })}
        </Container>
      )}
      {products && products.length == totalProductsAllowed && (
        <Button text="VIEW ORDER" onClick={openModal} />
      )}
      {products &&
        isMobileWidth !== true &&
        products.length > 0 &&
        products.map((product: BundleProduct) => {
          return (
            <StyledContainer flexDirection="column" padding={25}>
              <P bold>{product.title}</P>
              <P onClick={() => setProductToRemove(product)}>Remove</P>
            </StyledContainer>
          );
        })}

      {/* Modal */}
      <Modal
        style={customStyles}
        isOpen={modalVisible}
        onRequestClose={closeModal}
        zIndex={10}
      >
        <Container flexDirection="column" padding="50px">
          <P fontSize={30} bold>
            Your Bundle
          </P>
          {products &&
            products.length > 0 &&
            products.map((product: BundleProduct) => {
              return (
                <StyledContainer flexDirection="column" margin="5px 0px">
                  <P>{product.title}</P>
                </StyledContainer>
              );
            })}
          <Container margin="50px auto" />

          <Container justifyContent="space-evenly">
            <Button text="ADD TO CART" onClick={handleSubmit} />
            <Button
              theme="transparent"
              text="CHANGE ORDER"
              onClick={closeModal}
            />
          </Container>
        </Container>
      </Modal>
    </Container>
  );
};

export default BundlesMenu;
